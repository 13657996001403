/* eslint-disable no-nested-ternary */
import { Badge, Popover, Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import BaseTag from 'components/elements/BaseTag'
import { globalConfirm, globalToaster } from 'components/elements/global'
import {
 SVGMarketUpdateIcon,
 SVGNeighbourhoodListingAlert,
 SVGNewListingAlert,
 SVGNewsLetterIcon,
 SVGOpenHouse,
 SvgActionPlanIcon,
 SvgAlertIcon,
 SvgDeleteIcon,
 SvgEmailIcon,
 SvgStageIcon,
 SvgTagsIcon,
 SvgTextIcon,
} from 'components/icons'
import {
 //  AssignStageModal,
 AssignToAlertModal,
 SendEmailModal,
 SendTextModal,
} from 'components/shared/Modals'
import AssignAutomation from 'components/shared/Modals/AssignAutomation'
import ImportLeadModal from 'components/shared/Modals/ImportLeadModal'
// import LeadTransferModal from 'components/shared/Modals/LeadTransferModal'
import useAuth from 'hooks/useAuth'
import { FC, useState, useMemo } from 'react'
// import { useNavigate } from 'react-router'
import { utils } from 'utils'
import { useGlobal } from 'raducer/global'
import LeadTransferBtn from 'features/realtors/components/LeadTransferBtn'
import AssignTagsStagesModal from 'components/shared/Modals/AssignTagsStagesModal'
import { TSelectAllDropdown } from 'components/shared/MDataTable/type'
import useAppControl from 'hooks/useAppControl'
import { TAlertFeedType } from 'features/alert'
import { useDispatch } from 'react-redux'
import MessageModal from 'components/shared/Modals/MessageModal'
import { useDeleteLeadsMutation, useLeadExportMutation, leadApi } from '../api'
import { TGetLeads, TLeadCustomSelectAll } from '../types'
import LeadForm from './LeadForm'
import SelectedLeadModal from './SelectedLeadModal'
import ExportLead from './ExportLead'

const BuilkActionBlock: FC<{
 onRemoveSelectedKey?: (id?: any) => void
 leadRefresh?: () => void
 selectedRows?: TGetLeads[]
 clearAllKeys?: () => void
 selectAll: boolean
 unSelected?: any[]
 filterInfo?: string
 totalCount?: number
 selectedLeadsType?: 'all-leads' | 'my-leads'
 hasMore?: boolean
 selectAllType?: TLeadCustomSelectAll
 leads?: TGetLeads[]
}> = ({
 selectedRows,
 leadRefresh,
 onRemoveSelectedKey,
 clearAllKeys,
 selectAll,
 filterInfo,
 unSelected,
 totalCount,
 selectedLeadsType,
 hasMore,
 selectAllType,
 leads = [],
}) => {
 const { global } = useGlobal()
 const d = useDispatch()
 const [deleteLeads] = useDeleteLeadsMutation()
 const [exportLead, { isLoading }] = useLeadExportMutation()
 const [showModal, setShowModal] = useState<
  | false
  | 'lead'
  | 'tag'
  | 'stage'
  | 'forsale-alert'
  | 'sold-alert'
  | 'openhouse-alert'
  | 'action-plan'
  | 'market-updates'
  | 'real-estate-news'
  | 'import'
  | 'email'
  | 'sms'
  | 'lead-modal'
  | 'import-lead-warning'
  | 'export'
 >(false)
 const { user } = useAuth()
 const { updateSendSMSWidget, updateSendEmailWidget } = useAppControl()

 const selectedKeys: any[] = useMemo(
  () => (selectedRows ? selectedRows?.map((v) => v.id) : []),
  [selectedRows],
 )
 const selectedEmails: any[] = useMemo(
  () =>
   selectedRows
    ? selectedRows?.map((v) => v.email_address && v.email_address.email)
    : [],
  [selectedRows],
 )
 const selectedPhones: any[] = useMemo(
  () =>
   selectedRows
    ? selectedRows?.map((v) => v.phone_number && v.phone_number.number)
    : [],
  [selectedRows],
 )

 const handleBody = (key: string, value: any): any => {
  if (selectAllType === null) {
   return { [key]: value }
  }
  return {
   unselected: unSelected,
   //  select_all: selectAll,
   customtSelectAll: selectAllType,
   filter_info: filterInfo,
  }
 }

 const handleExportLead = async () => {
  const data = await exportLead({
   ...handleBody('lead_id', selectedKeys),
   crm_list_type: selectedLeadsType === 'my-leads' ? 'myleads' : 'AllLeads',
  }).unwrap()
  if (data) {
   utils.downloadFile(data)
  }
 }

 const handleDeleteLeads = async (): Promise<void> => {
  globalConfirm({
   cancelText: 'No',
   okText: 'Yes',
   content: 'Are you sure want to delete selected leads?',
   onOk: async () => {
    // await deleteLeads({ id: selectedKeys }).unwrap()
    await deleteLeads({
     ...handleBody('id', selectedKeys),
     crm_list_type: selectedLeadsType === 'my-leads' ? 'myleads' : 'AllLeads',
    }).unwrap()

    clearAllKeys?.()
    leadRefresh?.()
    d(leadApi.util.invalidateTags(['LEAD-TILE']))
   },
  })
 }

 const commonModalProps = {
  customtSelectAll: selectAllType,
  unSelected,
  filterInfo,
  onCancel: () => setShowModal(false),
 }

 const selectedLeadBtnDisabled = useMemo(() => {
  if (selectAllType === 'all') {
   return !(selectedRows?.length === totalCount) && hasMore
  }
  if (selectAllType !== null) {
   if (leads?.length < Number(selectAllType ?? 0)) {
    return true
   }
  }
 }, [selectAllType, selectedRows, totalCount, hasMore, leads])

 const selectedLeadBtnCount = useMemo(() => {
  if (selectAllType === 'all') {
   return selectedLeadBtnDisabled
    ? `${(totalCount ?? 0) - (unSelected?.length || 0)}`
    : selectedKeys.length ?? 0
  }
  if (selectAllType === null) {
   return selectedKeys.length ?? 0
  }

  if (selectAllType !== null) {
   return selectedLeadBtnDisabled
    ? Number(selectAllType) - (unSelected?.length || 0)
    : selectedKeys.length ?? 0
  }
 }, [
  selectAllType,
  totalCount,
  unSelected,
  selectedKeys,
  selectedLeadBtnDisabled,
 ])

 return (
  <>
   <Space style={{ marginRight: '20px' }}>
    {(selectedRows?.length ?? 0) > 0 && (
     <BaseButton
      type="link"
      disabled={selectedLeadBtnDisabled}
      onClick={() => setShowModal('lead-modal')}
     >
      Selected Leads
      <Badge
       className="ms-1"
       count={selectedLeadBtnCount}
       overflowCount={999999}
      />
     </BaseButton>
    )}
    <BaseButton
     tooltipText="Create New Lead"
     shape="circle"
     onClick={() => setShowModal('lead')}
     buttonType="gamma"
     icon={<i className="far fa-plus" />}
    />
    {(selectedLeadsType === 'my-leads' || user?.account_type === 'Realtor') && (
     <BaseButton
      tooltipText="Delete Lead"
      shape="circle"
      onClick={handleDeleteLeads}
      disabled={!selectedRows?.length}
      buttonType="beta"
      icon={<SvgDeleteIcon />}
     />
    )}
    {(selectedLeadsType === 'my-leads' || user?.account_type === 'Realtor') && (
     <>
      <BaseButton
       tooltipText="Email"
       shape="circle"
       disabled={!selectedRows?.length}
       onClick={() => {
        updateSendEmailWidget({
         selectAll,
         unSelected,
         filterInfo,
         to: selectedEmails?.filter((i: any) => i !== null && i !== undefined),
         extraBody: { crm_list_type: 'myLeads' },
         showMoreLabel:
          (selectAllType === 'all' && hasMore) ||
          (selectAllType !== 'all' &&
           selectAllType !== null &&
           leads?.length < Number(selectAllType)),
         widgetFrom: 'lead',
         show: true,
         selectedToCount: Number(selectedLeadBtnCount ?? '0') || 0,
         customtSelectAll: selectAllType,
        })
        if (
         selectedEmails?.includes?.(null) ||
         selectedEmails?.includes?.(undefined)
        ) {
         globalToaster({
          type: 'error',
          content: 'Lead(s) without valid Email ID skipped!',
         })
        }
       }}
       buttonType="alpha"
       icon={<SvgEmailIcon />}
      />
      {user?.twilio_phone && (
       <BaseButton
        tooltipText="Smart SMS"
        shape="circle"
        disabled={!selectedRows?.length}
        onClick={() => {
         updateSendSMSWidget({
          selectAll,
          unSelected,
          filterInfo,
          to: selectedPhones?.filter((i: any) => i !== null && i !== undefined),
          extraBody: { crm_list_type: 'myLeads' },
          // showMoreLabel: selectAll && hasMore,
          showMoreLabel:
           (selectAllType === 'all' && hasMore) ||
           (selectAllType !== 'all' &&
            selectAllType !== null &&
            leads?.length < Number(selectAllType)),
          widgetFrom: 'lead',
          show: true,
          selectedToCount: Number(selectedLeadBtnCount ?? '0') || 0,
          customtSelectAll: selectAllType,
         })
         //  setShowModal('sms')
         if (
          selectedPhones?.includes?.(null) ||
          selectedPhones?.includes?.(undefined)
         ) {
          globalToaster({
           type: 'error',
           content: 'Lead(s) without valid Phone Number skipped!',
          })
         }
        }}
        buttonType="alpha"
        icon={<SvgTextIcon />}
       />
      )}
      <BaseButton
       tooltipText="Tags"
       shape="circle"
       disabled={!selectedRows?.length}
       onClick={() => setShowModal('tag')}
       buttonType="alpha"
       icon={<i className="far fa-tags" style={{ fontSize: '17px' }} />}
      />
      <BaseButton
       tooltipText="Stage"
       shape="circle"
       disabled={!selectedRows?.length}
       onClick={() => setShowModal('stage')}
       buttonType="alpha"
       icon={<i className="far fa-th" style={{ fontSize: '17px' }} />}
      />
      {!(global?.listing_plan === 'Pre-Condo') && (
       <>
        <BaseButton
         tooltipText="New Listing Alert"
         shape="circle"
         disabled={!selectedRows?.length}
         onClick={() => setShowModal('forsale-alert')}
         buttonType="alpha"
         icon={<SVGNewListingAlert />}
        />
        {global?.user_type_sold && (
         <BaseButton
          tooltipText="Neighbourhood Alert"
          shape="circle"
          disabled={!selectedRows?.length}
          onClick={() => setShowModal('sold-alert')}
          buttonType="alpha"
          icon={<SVGNeighbourhoodListingAlert />}
          customClass="bell-icon-btn"
         />
        )}
        {global?.open_house && (
         <BaseButton
          tooltipText="Open House Alert"
          shape="circle"
          disabled={!selectedRows?.length}
          onClick={() => setShowModal('openhouse-alert')}
          buttonType="alpha"
          icon={<SVGOpenHouse />}
         />
        )}
       </>
      )}
      <BaseButton
       tooltipText="Action plan"
       shape="circle"
       disabled={!selectedRows?.length}
       onClick={() => setShowModal('action-plan')}
       buttonType="alpha"
       icon={<SvgActionPlanIcon />}
      />
      <BaseButton
       tooltipText="Market Updates"
       shape="circle"
       disabled={!selectedRows?.length}
       onClick={() => setShowModal('market-updates')}
       buttonType="alpha"
       icon={<SVGMarketUpdateIcon />}
      />
      <BaseButton
       tooltipText="Real Estate Newsletter"
       shape="circle"
       disabled={!selectedRows?.length}
       onClick={() => setShowModal('real-estate-news')}
       buttonType="alpha"
       icon={<SVGNewsLetterIcon />}
      />
     </>
    )}
    {user?.account_type === 'Brokerage' && (
     <LeadTransferBtn
      id={JSON.stringify(selectedKeys)}
      btnDisabled={!selectedRows?.length}
      type="icon"
      onSuccess={() => {
       leadRefresh?.()
       clearAllKeys?.()
      }}
      {...commonModalProps}
      extraBody={{
       crm_list_type: selectedLeadsType === 'my-leads' ? 'myLeads' : 'AllLeads',
      }}
      searchExtraBody={{ type: 'crm_leads' }}
     />
    )}

    <BaseButton
     tooltipText="Export Lead"
     shape="circle"
     disabled={!selectedRows?.length}
     onClick={() => setShowModal('export')}
     buttonType="alpha"
     icon={<i className="far fa-file-export" />}
     loading={isLoading}
    />

    <BaseButton
     tooltipText="Import"
     shape="circle"
     onClick={() => {
      if (global?.lead_assign_type === 'Automatic') {
       setShowModal('import-lead-warning')
      } else {
       setShowModal('import')
      }
     }}
     buttonType="alpha"
     icon={<i className="far fa-file-import" />}
    />
   </Space>

   {showModal === 'lead' && (
    <LeadForm
     visible={showModal === 'lead'}
     onCancel={() => setShowModal(false)}
    />
   )}
   {(showModal === 'forsale-alert' ||
    showModal === 'sold-alert' ||
    showModal === 'openhouse-alert') && (
    <AssignToAlertModal
     selectedKeys={selectedKeys}
     visible
     //  type={showModal === 'alert' ? 'new' : 'sold'}
     //  type={
     //   showModal === 'alert'
     //    ? 'forsale'
     //    : showModal === 'neighbourhood-alert'
     //    ? 'sold'
     //    : 'openhouse'
     //  }
     type={showModal?.split('-')?.[0] as TAlertFeedType}
     extraBody={{ crm_list_type: 'myLeads' }}
     {...commonModalProps}
    />
   )}

   {(showModal === 'tag' || showModal === 'stage') && (
    <AssignTagsStagesModal
     refetch={leadRefresh}
     selectedKeys={selectedKeys}
     visible
     assignType={showModal}
     {...commonModalProps}
     extraBody={{ crm_list_type: 'myLeads' }}
    />
   )}

   {(showModal === 'action-plan' ||
    showModal === 'real-estate-news' ||
    showModal === 'market-updates') && (
    <AssignAutomation
     lead_id={selectedKeys}
     visible
     {...commonModalProps}
     extraBody={{ crm_list_type: 'myLeads' }}
     from={showModal}
    />
   )}

   {showModal === 'import-lead-warning' && (
    <MessageModal
     visible={showModal === 'import-lead-warning'}
     onHide={() => setShowModal('import')}
     message="Please note that auto-transfer is enabled. Transfer Summary will not be generated for the transfer of leads during import"
    />
   )}

   {showModal === 'import' && (
    <ImportLeadModal
     onCancel={() => setShowModal(false)}
     refresh={() => {
      leadRefresh?.()
      d(leadApi.util.invalidateTags(['LEAD-TILE']))
     }}
     visible={showModal === 'import'}
    />
   )}

   {showModal === 'lead-modal' && selectedRows?.length && (
    <SelectedLeadModal
     visible
     onCancel={() => setShowModal(false)}
     handleRemove={(v) => {
      onRemoveSelectedKey?.(v?.id)
     }}
     data={selectedRows}
     onClearAll={clearAllKeys}
     isRealtor={false}
    />
   )}
   {showModal === 'export' && (
    <ExportLead
     {...commonModalProps}
     selectedKeys={selectedKeys}
     selectedLeadsType={selectedLeadsType}
     //  selectAll={selectAll}
    />
   )}
  </>
 )
}

export default BuilkActionBlock
